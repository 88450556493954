import React, { useContext } from "react";
import styled from "styled-components";
import Chat from "../../../models/chat.model";
import { ChatPageContext } from "../../../contexts/chat-page.context";
import { postMessage } from "../../../services/dispatcher.service";

interface IUrlCard {
  chatM: Chat;
}

const UrlCard: React.FC<IUrlCard> = ({ chatM }) => {
  const { cards } = chatM.customPayload;
  const { appendChatMs, sessionIdRef, setAudioChecker } = useContext(ChatPageContext);

  const onClickHandler = async (trackingMessage: string) => {
    if (trackingMessage) {
      const data = await postMessage(trackingMessage, sessionIdRef?.current!);
      const newChatArray2: Chat[] = Chat.getBotObjects(data);
      await appendChatMs(newChatArray2);
      setAudioChecker("finish");
    }
  };

  return (
    <>
      {cards.map((el: any, index: number) => {
        return (
          <SUrlCardContainer key={index} onClick={() => onClickHandler(el.trackingMessage)}>
            <SUrlCardLinkContainer>{el.text}</SUrlCardLinkContainer>
          </SUrlCardContainer>
        );
      })}
    </>
  );
};

export default UrlCard;

const SUrlCardContainer = styled.div`
  background: ${(props) => props.theme.palette.secondary.main};
  color: white;
  padding: 10px 5px;
  margin-top: 20px;
  text-align: center;
  /* border: 1px solid black; */
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

const SUrlCardLinkContainer = styled.div`
  padding: 0px;
  margin-top: 0px;
  width: 100%;
  text-decoration: none;
  color: inherit;
`;
