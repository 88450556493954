import React from "react";
import SingleSelection from "./message-component/input/SingleSelection";
// import LocationSelector from "./message-component/input/LocationSelector";
import SelectedSatisficationFeedback from "./message-component/input/SelectedSatisficationFeedback";
import LocationSelector from "../components/list-selector/LocationSelector";
import ScreeningPackage from "../components/list-selector/ScreeningPackage";
import MultipleChoiceSelection from "./list-selector/MultipleChoiceSelection";

export const MessageHelper = ({ type }: any) => {
  if (type.customPayload === undefined) {
    return null;
  } else {
    const { customPayload } = type;
    if (customPayload.type === "optionSelector") {
      return (
        <SingleSelection
          optionsTitle={customPayload.optionsTitle}
          options={customPayload.options}
        ></SingleSelection>
      );
    } else if (customPayload.type === "locationSelector") {
      return (
        <SingleSelection
          optionsTitle={customPayload.optionsTitle}
          options={customPayload.options}
        ></SingleSelection>
      );
    } else if (customPayload.type === "locationConfirmation") {
      return (
        <SingleSelection
          optionsTitle={customPayload.optionsTitle}
          options={customPayload.options}
        ></SingleSelection>
      );
    }
    return null;
  }
};

export const SelectorHelper = ({ customPayload, isEnable }: any) => {
  if (customPayload === undefined) {
    return null;
  } else {
    // custom payload that fulfillment send
    if (customPayload.type === "locationSelector") {
      return (
        <LocationSelector
          isEnable={isEnable}
          locationJsonString={customPayload.locationJsonString}
          currentSelected={customPayload.currentSelectedIndex}
        ></LocationSelector>
      );
    } else if (customPayload.type === "healthScreeningPackage") {
      return (
        <ScreeningPackage
          isEnable={isEnable}
          screeningPackageJsonString={customPayload.screeningPackage}
          currentSelected={customPayload.currentSelectedIndex}
        />
      );
    } else if (customPayload.type === "satificationFeedback") {
      return (
        <SelectedSatisficationFeedback
          isEnable={isEnable}
          options={customPayload.options}
          currentSelected={customPayload.currentSelectedIndex}
        />
      );
    } else if (customPayload.type === "multipleChoiceSelection") {
      return (
        <MultipleChoiceSelection
          options={customPayload.choice}
          title={customPayload.title}
        />
      );
    }
    return null;
  }
};
