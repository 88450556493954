import React from "react";
import styled from "styled-components";
// import PersonIcon from "@mui/icons-material/Person";

import Chat from "../../models/chat.model";
import TextBubble from "./TextBubble";
import DotsWave from "./DotsWave";
import BotFloatingButton from "../../assets/Icons/BotFloatingButton.svg";
import UrlCard from "../message-component/display/UrlCard";

interface ChatElProps {
  chatM: Chat | any;
  showAvatar: boolean;
  int?: number;
}

const ChatEl: React.FC<ChatElProps> = ({ int, chatM, showAvatar }) => {
  const getChatContent = () => {
    if (chatM.isLoading()) {
      return <DotsWave />;
    }

    if (chatM.isText()) {
      if(chatM.text.includes("\n") && chatM.isFromBot()){
        const currText: string[] = chatM.text.split("\n");
        return <div>
        {currText.map((text,i) =>{
           if(currText.length -1 === i){
             return <STextBubbleWrapper  key={`${i}${text}`} >
              <TextBubble key={`${i}${text}`} isFromBot={chatM.isFromBot()} text={text} showAvatar={showAvatar}>
              {chatM.isUrl() ? <UrlCard chatM={chatM} /> : undefined}
            </TextBubble>
           </STextBubbleWrapper>;
           }else{
             return <STextBubbleWrapper key={`${i}${text}`}>
              <TextBubble key={`${i}${text}`} isFromBot={chatM.isFromBot()} text={text} showAvatar={showAvatar}>
              {undefined}
              </TextBubble>
           </STextBubbleWrapper>;
 
           }
        })}
        </div>
      }else{
        return (
          <TextBubble  isFromBot={chatM.isFromBot()}  text={chatM.text} showAvatar={showAvatar}>
            {chatM.isUrl() ? <UrlCard chatM={chatM} /> : undefined}
          </TextBubble>
        );
      }
     
    }

  };

  return (
    <>
      {chatM.text === "" ? null : (
        <SChatEl>
          {showAvatar && (
            <img
              style={{ width: "26.88px", height: " 26.88px", paddingLeft: "10px" }}
              src={BotFloatingButton}
              alt="bot-icon"
            />
          )}
          <SBubbleAndTime>{getChatContent()}</SBubbleAndTime>
        </SChatEl>
      )}
    </>
  );
};

export default ChatEl;

const SChatEl = styled.div`
  display: flex;
  height: fit-content;
  & + & {
    margin-top: 16px;
  }
  margin-top: 8px;
  margin-bottom: 8px;
`;

const SBubbleAndTime = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
`;

const STextBubbleWrapper = styled.div`
  flex-direction: column;
  display: flex;
  height: fit-content;
  & + & {
    margin-top: 8px;
  }
  /* margin-top: 8px; */
  margin-bottom: 8px;
`;